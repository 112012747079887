import http from '@/util/http'
export async function create_investment(data) {
    return (await http.post('investment/create',data)).data;
}

export async function get_investments(data) {
    return (await http.get('investment/list',{params:data})).data;
}

export async function get_projects() {
    return (await http.get('investment/view')).data;
}

export async function delete_investment(id) {
    return (await http.delete(`investment/delete/${id}`)).data;
}

export async function edit_investment(id) {
    return (await http.get(`investment/edit/${id}`)).data;
}

export async function update_investment(id,data) {
    return (await http.post(`investment/update/${id}`,data)).data;
}
export async function get_investments_graph_data(data){
    return (await http.post('/investment/graph',data)).data
}
export async function get_all_investment_list(){
    return (await http.get('/investment/all/investments')).data
}