import http from '@/util/http'
export async function get_list(params) {
    return (await http.post('roi/createtype',{value: params})).data;
}
export async function create_investment_return(data) {
    return (await http.post('roi/create',data)).data;
}
export async function get_investment_return(params) {
    return (await http.get('roi/list',{params})).data;
}
export async function delete_investment_return(id) {
    return (await http.delete(`roi/delete/${id}`)).data;
}
export async function edit_investment_return(id) {
    return (await http.get(`roi/edit/${id}`)).data;
}
export async function update_investment_return(id,data) {
    return (await http.post(`roi/update/${id}`,data)).data;
}
export async function get_roi_graph_data(data) {
    return (await http.post('/roi/graph',data)).data
}